<template>
  <el-dialog :title="isEdit ? '编辑回访记录' : '新增回访记录'" width="800px" :visible="show" @close="close" :close-on-click-modal="false">
    <el-form ref="form" :model="form" :rules="rules" inline label-width="100px">
      <el-form-item label="回访主题" prop="topic">
        <el-input v-model="form.topic" placeholder="请输入" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="企业账号" prop="account">
        <el-input v-model="form.account" placeholder="请输入" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="企业名称" prop="name">
        <el-input v-model="form.name" placeholder="请输入" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="回访时间" prop="visitTime">
        <el-date-picker v-model="form.visitTime" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss" style="width: 250px"> </el-date-picker>
      </el-form-item>
      <el-form-item label="回访人员">
        <el-input v-model="form.visitUser" placeholder="请输入" style="width: 250px"></el-input>
      </el-form-item>
      <el-form-item label="联系电话">
        <el-input v-model="form.phone" placeholder="请输入" style="width: 250px"></el-input>
      </el-form-item>
    </el-form>
    <div class="mb10 font12">回访记录</div>
    <wang-editor :value="form.detail" @change="(data) => (form.detail = data)" />
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="add">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import api from '@/api/operate/returnVisit'
import WangEditor from '@/components/wangEditor'
export default {
  props: {
    show: {
      default: false,
      type: Boolean,
    },
    detail: {
      default: null,
      type: Object,
    },
  },
  components: { WangEditor },
  data() {
    return {
      form: {},
      isEdit: false,
      rules: {
        topic: [{ required: true, message: '请输入回访主题', trigger: 'blur' }],
        account: [{ required: true, message: '请输入企业账号', trigger: 'blur' }],
        name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        visitTime: [{ required: true, message: '请选择回访时间', trigger: 'blur' }],
      },
    }
  },
  watch: {
    show(val) {
      if (val) {
        this.isEdit = !_.isEmpty(this.detail)
        this.form = this.isEdit ? this.detail : {}
      } else {
        this.$refs['form'].resetFields()
      }
    },
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    add() {
      this.$refs['form'].validate((valid) => {
        if (!valid) return
        let params = this.form
        let handleType = this.isEdit ? 'modify' : 'add'
        api[handleType](params).then(() => {
          this.$msg.success('操作成功')
          this.$emit('close')
        })
      })
    },
  },
}
</script>
