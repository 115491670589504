<!-- 应用标签 -->
<template>
  <div class="h100">
    <common-layout>
      <template #header>
        <el-form inline>
          <el-form-item label="主题">
            <el-input placeholder="请输入" v-model="form.topic" clearable></el-input>
          </el-form-item>
          <el-form-item label="企业账号">
            <el-input placeholder="请输入" v-model="form.account" clearable></el-input>
          </el-form-item>
          <el-form-item label="企业名称">
            <el-input placeholder="请输入" v-model="form.name" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="query">查询</el-button>
            <el-button type="primary" plain @click="handleAdd">新增</el-button>
          </el-form-item>
        </el-form>
      </template>
      <template>
        <el-table :data="tableData" v-loading="tableLoading" border stripe height="100%">
          <el-table-column label="主题" prop="topic" align="center" min-width="150"></el-table-column>
          <el-table-column label="企业账号" prop="account" align="center" min-width="150"></el-table-column>
          <el-table-column label="企业名称" prop="name" align="center" min-width="150"></el-table-column>
          <el-table-column label="回访人员" prop="visitUser" align="center" min-width="150"></el-table-column>
          <el-table-column label="联系电话" prop="phone" align="center" min-width="150"></el-table-column>
          <el-table-column label="回访时间" prop="visitTime" align="center" min-width="180"></el-table-column>
          <el-table-column label="录入账号" prop="createUser" align="center" min-width="150"></el-table-column>
          <el-table-column label="录入时间" prop="createTime" align="center" min-width="180"></el-table-column>
          <el-table-column label="修改账号" prop="updateUser" align="center" min-width="150"></el-table-column>
          <el-table-column label="修改时间" prop="updateTime" align="center" min-width="180"></el-table-column>
          <el-table-column label="操作" align="center" min-width="150" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="handleEdit(scope.row)"> 编辑 </el-button>
              <!-- <el-button type="text" @click="handleEditStatus(scope.row)"> {{ scope.row.useStatus ? '禁用' : '启用' }} </el-button> -->
              <el-button type="text" @click="handleDelete(scope.row)"> 删除 </el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-pagination class="mt10" :page-size="page.size" :current-page="page.current" :total="total" @size-change="handleSizeChange" @current-change="handleCurrentChange" layout="total, sizes, prev, pager, next, jumper"></el-pagination>
      </template>
    </common-layout>
    <add-modal :show.sync="showAdd" :detail="curRow" @close="closeModal" />
  </div>
</template>

<script>
import api from '@/api/operate/returnVisit'
import addModal from './addModal'
export default {
  components: { addModal },
  data() {
    return {
      form: {},
      tableData: [],
      tableLoading: false,
      page: {
        current: 1,
        size: 20,
      },
      total: 0,
      showAdd: false,
      curRow: null,
    }
  },
  created() {
    this.query()
  },
  methods: {
    query() {
      let params = {
        ...this.form,
        ...this.page,
      }
      this.tableLoading = true
      api
        .query(params)
        .then((res) => {
          this.tableData = res.data.records
          this.total = res.data.total
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    handleAdd() {
      this.curRow = null
      this.showAdd = true
    },
    handleEdit(row) {
      this.curRow = _.cloneDeep(row)
      this.showAdd = true
    },
    closeModal() {
      this.showAdd = false
      this.query()
    },
    handleEditStatus(row) {
      this.$confirm(`确定${row.useStatus ? '禁用' : '启用'}此数据吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        let params = _.cloneDeep(row)
        params.useStatus = !params.useStatus
        api.modify(params).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleDelete(row) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        api.delete({ id: row.id }).then(() => {
          this.$msg.success('操作成功')
          this.query()
        })
      })
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.query()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.page.current = 1
      this.query()
    },
  },
}
</script>
<style lang="scss" scoped></style>
